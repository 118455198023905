import { ReactNode } from 'react';
import { Typography } from '@/components/Typography';
import './faq.scss';
import { Link } from 'gatsby';

export const FAQs = () => {
  return (
    <div className="faq-wrapper col-sm-12 col-lg-7">
      {data.map(({ question, answer }, idx) => (
        <div key={idx} className="faq-section">
          <h4>{question}</h4>
          <p>{answer}</p>
        </div>
      ))}
    </div>
  );
};

const data: Array<{
  question: ReactNode;
  answer: ReactNode;
}> = [
  {
    question: 'How do you calculate price?',
    answer: `Pricing depends on only three components: the number of Control Planes, the number of Applications, and the level of support. In other words, you only pay for the true usage, regardless of pods, nodes, managed clusters, or number of developers.`
  },
  {
    question: 'What is an Application Package (App Pack)?',
    answer: 'One App Pack is made of 10 Argo CD Applications.'
  },
  {
    question: 'What is a Control Plane?',
    answer: 'A Control Plane refers to an Argo CD instance.'
  },
  {
    question: 'What is an Application?',
    answer:
      'Application refers to an Argo CD application. Every card in the Argo CD UI is considered an application.'
  },
  {
    question: 'How many Control Planes do I need?',
    answer: (
      <>
        Akuity Platform allows for a more secured Hub-and-Spoke model. This
        model is recommended to get a holistic view of all applications across
        all clusters, which in turn provides better developer experience.
        <br />
        <br />
        It&apos;s also common to have 1 or 2 extra Control Planes for
        experimental environments.
        <br />
        <br />
        The Akuity Platform supports an unlimited number of Argo CD instances
        (Control Planes). For more info, please refer to the{' '}
        <Link to="/blog/argo-cd-architectures-explained/">
          “How many Argo CDs Do You need”
        </Link>{' '}
        blog post, or <Link to="/talk-to-sales/">contact sales</Link> for more
        information.
      </>
    )
  },
  {
    question: 'How can I pay?',
    answer: (
      <>
        You can use your credit card and purchase directly on your organization's
        billing page inside the platform, or you can <Link to="/talk-to-sales">
        contact sales</Link> to request
        an order form or a purchase order (PO) number.
      </>
    )
  },
  {
    question: 'What are the different support options available?',
    answer: (
      <ul>
        <li>
          Enterprise and Platinum support for Argo CD on the Akuity Platform
        </li>
        <li>Argo CD Open Source Support</li>
      </ul>
    )
  },
  {
    question: 'What is included in the support package?',
    answer: (
      <ul>
        <li>Best practices and brainstorming sessions on Argo CD</li>
        <li>How to scale securely and avoid common pitfalls</li>
        <li>Argo CD functionality support</li>
        <li>Help troubleshooting Argo CD</li>
        <li>Escalating Feature Request for Argo CD</li>
        <li>SLAs to respond to CVEs</li>
      </ul>
    )
  }
];

import React from 'react';
import { Button } from '@/components/Button';
import Layout from '@/components/Layout';
import './pricing-and-plans.scss';
import { FAQs } from '@/sections/Pricing/faq';
import CaretIcon from '@/static/assets/icons/Navigation/icn-caret.svg';
import { seoConfiguration } from '@/utils/seo-config';

const PricingAndPlansPage = () => {
  const [showPlan1, setShowPlan1] = React.useState(false);
  const [showPlan2, setShowPlan2] = React.useState(false);
  const [showPlan3, setShowPlan3] = React.useState(false);

  return (
    <Layout {...seoConfiguration.pricing}>
      <div className="pricing-page">
        <h1>Pricing & Plans</h1>
        <h2>
          One size doesn&apos;t fit all. <br />
          Choose what&apos;s right for your team.
        </h2>

        <div className="plans">
          <div className="plan">
            <div className="plan__header">
              <div>
                All plans start with 30-day free trial, no credit card required.
              </div>
            </div>
          </div>

          {/* PLAN Starter - START */}
          <div className={`plan${showPlan1 ? ' plan--active' : ''}`}>
            <div className="plan__header">
              <h3>Starter</h3>
              <div className="plan__price">
                <div className="plan__price-value">$29</div>
                <div className="plan__price-info">
                  per
                  <br />
                  month
                </div>
              </div>
              <div className="plan__des">
                1 Control Plane
                <br />
                20 Applications
                <br />
                3 Members
                <br />3 Kubernetes Clusters
              </div>
              <div className="plan__btn">
                <Button
                  additionalClass="primary-button"
                  link="/signup"
                  isExternalLink
                >
                  Start a Free Trial
                </Button>
              </div>
            </div>
            <div
              className="plan__details-btn"
              onClick={() => setShowPlan1((v) => !v)}
            >
              {showPlan1 ? 'Close plan details' : 'Plan details'} <CaretIcon />
            </div>
            <div className="plan__body">
              <div className="plan__row">
                <div className="plan__key">Argo CD Control Planes</div>
                <div className="plan__value">1</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Applications</div>
                <div className="plan__value">20</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Org Members</div>
                <div className="plan__value">3</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Kubernetes Clusters</div>
                <div className="plan__value">3</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Workspaces</div>
                <div className="plan__value">1</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Support</div>
                <div className="plan__value">&#8212;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">
                  <u>System Accounts</u> (login or api)
                </div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Argo CD Notifications</div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Audit Records (Searchable)</div>
                <div className="plan__value">1 Month</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Audit Record Archive Export</div>
                <div className="plan__value">&#8212;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Audit Record Archive Retention</div>
                <div className="plan__value">&#8212;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Custom Roles</div>
                <div className="plan__value">&#8212;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Scoped API Keys</div>
                <div className="plan__value">&#8212;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Argo CD SSO</div>
                <div className="plan__value">&#8212;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">HA Control plane</div>
                <div className="plan__value">&#8212;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">
                  Akuity Extensions
                  <br />* Rollouts
                  <br />* Sync History
                  <br />* AI Assistant (Beta)
                </div>
                <div className="plan__value">&#8212;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">
                  Declarative Management
                  <br />
                  (Terraform/CLI)
                </div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">
                  App of Apps (in-cluster enabled)
                </div>
                <div className="plan__value">&#8212;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">ApplicationSets</div>
                <div className="plan__value">&#8212;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">
                  Custom Subdomain
                  <br />
                  (xxx.cd.akuity.cloud)
                </div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">
                  Custom Domain
                  <br />
                  (argocd.acme.com)
                </div>
                <div className="plan__value">&#8212;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Flexible architecture</div>
                <div className="plan__value">&#8212;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Agent State replication</div>
                <div className="plan__value">&#8212;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Deep links</div>
                <div className="plan__value">&#8212;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Custom styles</div>
                <div className="plan__value">&#8212;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Agent customization</div>
                <div className="plan__value">&#8212;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Config Management Plugins</div>
                <div className="plan__value">&#8212;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Enterprise SSO</div>
                <div className="plan__value">&#8212;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">
                  CVE notifications and SLA on resolution
                </div>
                <div className="plan__value">&#8212;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Self-hosted option</div>
                <div className="plan__value">&#8212;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">
                  Dedicated Customer Success Manager
                </div>
                <div className="plan__value">&#8212;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Real-time support channel</div>
                <div className="plan__value">&#8212;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Volume pricing discount</div>
                <div className="plan__value">&#8212;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Fast track feature option</div>
                <div className="plan__value">&#8212;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">
                  Optional Subject Matter Expert Support
                </div>
                <div className="plan__value">&#8212;</div>
              </div>
            </div>
            {showPlan1 && (
              <div
                className="plan__details-btn"
                onClick={() => setShowPlan1(false)}
              >
                Close plan details <CaretIcon />
              </div>
            )}
          </div>
          {/* PLAN Starter - END */}

          {/* PLAN Professional - START */}
          <div className={`plan${showPlan2 ? ' plan--active' : ''}`}>
            <div className="plan__header">
              <h3>Professional</h3>
              <div className="plan__price">
                <div className="plan__price-value">
                  <small>Starting from</small>$59
                </div>
                <div className="plan__price-info">
                  per app pack
                  <br />
                  per month*
                </div>
              </div>
              <div className="plan__des">
                Up to 10 Control Planes
                <br />
                Unlimited Kubernetes Clusters
                <br />
                Unlimited Members
                <br />
                Available
              </div>
              <div className="plan__des2 plan__des2--asterisk">
                $59 per month, per app package (app package = 10 Argo CD apps),
                5 packages minimum. One control plane is included, then $299 per
                month per additional control plane
              </div>
              <div className="plan__btn">
                <Button
                  additionalClass="primary-button"
                  link="/signup"
                  isExternalLink
                >
                  Start a Free Trial
                </Button>
              </div>
            </div>
            <div
              className="plan__details-btn"
              onClick={() => setShowPlan2((v) => !v)}
            >
              {showPlan2 ? 'Close plan details' : 'Plan details'} <CaretIcon />
            </div>
            <div className="plan__body">
              <div className="plan__row">
                <div className="plan__key">Argo CD Control Planes</div>
                <div className="plan__value">1 Included, up to 10</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Applications</div>
                <div className="plan__value">50 Included, up to 1500</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Org Members</div>
                <div className="plan__value">Unlimited</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Kubernetes Clusters</div>
                <div className="plan__value">Unlimited</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Workspaces</div>
                <div className="plan__value">3</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Support</div>
                <div className="plan__value">Available</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">System Accounts (login or api)</div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Argo CD Notifications</div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Audit Records (Searchable)</div>
                <div className="plan__value">6 Months</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Audit Record Archive Export</div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Audit Record Archive Retention</div>
                <div className="plan__value">1 Year</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Custom Roles</div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Scoped API Keys</div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Argo CD SSO</div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">HA Control plane</div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">
                  Akuity Extensions
                  <br />* Rollouts
                  <br />* Sync History
                  <br />* AI Assistant (Beta)
                </div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">
                  Declarative Management
                  <br />
                  (Terraform/CLI)
                </div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">
                  App of Apps (in-cluster enabled)
                </div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">ApplicationSets</div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">
                  Custom Subdomain
                  <br />
                  (xxx.cd.akuity.cloud)
                </div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">
                  Custom Domain
                  <br />
                  (argocd.acme.com)
                </div>
                <div className="plan__value">&#8212;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Flexible architecture</div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Agent State replication</div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Deep links</div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Custom styles</div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Agent customization</div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Config Management Plugins</div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Enterprise SSO</div>
                <div className="plan__value">&#8212;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">
                  CVE notifications and SLA on resolution
                </div>
                <div className="plan__value">&#8212;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Self-hosted option</div>
                <div className="plan__value">&#8212;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">
                  Dedicated Customer Success Manager
                </div>
                <div className="plan__value">&#8212;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Real-time support channel</div>
                <div className="plan__value">&#8212;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Volume pricing discount</div>
                <div className="plan__value">&#8212;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Fast track feature option</div>
                <div className="plan__value">&#8212;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">
                  Optional Subject Matter Expert Support
                </div>
                <div className="plan__value">&#8212;</div>
              </div>
            </div>
            {showPlan2 && (
              <div
                className="plan__details-btn"
                onClick={() => setShowPlan2(false)}
              >
                Close plan details <CaretIcon />
              </div>
            )}
          </div>
          {/* PLAN Professional - END */}

          {/* PLAN Enterprise - START */}
          <div className={`plan${showPlan3 ? ' plan--active' : ''}`}>
            <div className="plan__header">
              <h3>Enterprise</h3>
              <div className="plan__des2">
                Managed Argo CD and Enterprise Support for Argo Workflows, Argo
                CD, Rollouts and Events from the creators of Argo
              </div>
              <div className="plan__des">
                Unlimited Control Planes
                <br />
                Unlimited Applications
                <br />
                Unlimited Members
                <br />
                Unlimited Kubernetes Clusters
                <br />
                Available
                <br />
                Enterprise-exclusive features
                <br />
                Self-hosted and on-premise options
              </div>
              <div className="plan__btn">
                <Button additionalClass="primary-button" link="/talk-to-sales">
                  Talk to Sales
                </Button>
              </div>
            </div>
            <div
              className="plan__details-btn"
              onClick={() => setShowPlan3((v) => !v)}
            >
              {showPlan3 ? 'Close plan details' : 'Plan details'} <CaretIcon />
            </div>
            <div className="plan__body">
              <div className="plan__row">
                <div className="plan__key">Argo CD Control Planes</div>
                <div className="plan__value">Unlimited</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Applications</div>
                <div className="plan__value">Unlimited</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Org Members</div>
                <div className="plan__value">Unlimited</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Kubernetes Clusters</div>
                <div className="plan__value">Unlimited</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Workspaces</div>
                <div className="plan__value">Unlimited</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Support</div>
                <div className="plan__value">Available</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">
                  <u>System Accounts</u> (login or api)
                </div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Argo CD Notifications</div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Audit Records (Searchable)</div>
                <div className="plan__value">1 Year</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Audit Record Archive Export</div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Audit Record Archive Retention</div>
                <div className="plan__value">2 Years</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Custom Roles</div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Scoped API Keys</div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Argo CD SSO</div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">HA Control plane</div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">
                  Akuity Extensions
                  <br />* Rollouts
                  <br />* Sync History
                  <br />* AI Assistant (Beta)
                </div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">
                  Declarative Management
                  <br />
                  (Terraform/CLI)
                </div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">
                  App of Apps (in-cluster enabled)
                </div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">ApplicationSets</div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">
                  Custom Subdomain
                  <br />
                  (xxx.cd.akuity.cloud)
                </div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">
                  Custom Domain
                  <br />
                  (argocd.acme.com)
                </div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Flexible architecture</div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Agent State replication</div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Deep links</div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Custom styles</div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Agent customization</div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Config Management Plugins</div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Enterprise SSO</div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">
                  CVE notifications and SLA on resolution
                </div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Self-hosted option</div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">
                  Dedicated Customer Success Manager
                </div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Real-time support channel</div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Volume pricing discount</div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">Fast track feature option</div>
                <div className="plan__value">&#10004;</div>
              </div>
              <div className="plan__row">
                <div className="plan__key">
                  Optional Subject Matter Expert Support
                </div>
                <div className="plan__value">&#10004;</div>
              </div>
            </div>
            {showPlan3 && (
              <div
                className="plan__details-btn"
                onClick={() => setShowPlan3(false)}
              >
                Close plan details <CaretIcon />
              </div>
            )}
          </div>
          {/* PLAN Enterprise - END */}
        </div>

        <div className="plans-footer">
          <div className="plans-footer__plan">
            All plans start with 30-day free trial, no credit card required.
          </div>
          <div className="plans-footer__plan">
            <h3>Starter</h3>
            <div className="plan__price">
              <div className="plan__price-value">$29</div>
              <div className="plan__price-info">
                per
                <br />
                month
              </div>
            </div>
            <div className="plan__btn">
              <Button
                additionalClass="primary-button"
                link="/signup"
                isExternalLink
              >
                Start a Free Trial
              </Button>
            </div>
          </div>
          <div className="plans-footer__plan">
            <h3>Professional</h3>
            <div className="plan__price">
              <div className="plan__price-value">
                <small>Starting from</small>$59
              </div>
              <div className="plan__price-info">
                per app pack
                <br />
                per month*
              </div>
            </div>
            <div className="plan__btn">
              <Button
                additionalClass="primary-button"
                link="/signup"
                isExternalLink
              >
                Start a Free Trial
              </Button>
            </div>
            <div className="plan__des2 plan__des2--asterisk">
              $59 per month, per app package (app package = 10 Argo CD apps), 5
              packages minimum. One control plane is included, then $299 per
              month per additional control plane
            </div>
          </div>
          <div className="plans-footer__plan">
            <h3>Enterprise</h3>
            <div className="plan__des2">
              Managed Argo CD and Enterprise Support for Argo Workflows, Argo
              CD, Rollouts and Events from the creators of Argo
            </div>
            <div className="plan__btn">
              <Button additionalClass="primary-button" link="/talk-to-sales">
                Talk to Sales
              </Button>
            </div>
          </div>
        </div>

        <FAQs />
      </div>
    </Layout>
  );
};

export default PricingAndPlansPage;
